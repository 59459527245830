import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const TestimonialSliderVideos = () => {
    const [testimonialSliderVideos, setTestimonialSliderVideos] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [videoPreview, setVideoPreview] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTestimonialSliderVideos = async () => {
            try {
                const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/get-testimonial-slider-videos');
                const testimonialSliderVideosData = response.data;

                if (testimonialSliderVideosData && testimonialSliderVideosData.testimonialSliderVideoUrl) {
                    setTestimonialSliderVideos(testimonialSliderVideosData.testimonialSliderVideoUrl);
                } else {
                    console.error('Invalid or missing testimonialSliderVideoUrl data in the API response:', testimonialSliderVideosData);
                    setError('Error fetching testimonial slider videos');
                }
            } catch (error) {
                console.error('Error fetching testimonial slider videos:', error);
                setError('Error fetching testimonial slider videos');
            }
        };

        fetchTestimonialSliderVideos();
    }, []);

    const handleVideoEdit = async (index) => {
        try {
            const editFileInput = document.createElement('input');
            editFileInput.type = 'file';
            editFileInput.accept = 'video/*';

            editFileInput.onchange = async (event) => {
                const confirmed = window.confirm('Are you sure you want to edit this video?');

                if (confirmed) {
                    const file = event.target.files[0];
                    if (file) {
                        const formData = new FormData();
                        formData.append('testimonialSliderVideo', file);

                        const response = await axios.put(`https://fitandfamousshowbackend.evertizsolutions.com/api/update-testimonial-slider-video/${index}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });

                        if (response.status === 200) {
                            const { testimonialSliderVideoUrl } = response.data;
                            const updatedVideos = [...testimonialSliderVideos];
                            updatedVideos[index] = testimonialSliderVideoUrl;
                            setTestimonialSliderVideos(updatedVideos);
                        } else {
                            console.error('Error updating testimonial slider video:', response.data);
                        }
                    }
                }
            };

            // Click the edit file input programmatically
            editFileInput.click();
        } catch (error) {
            console.error('Error editing testimonial slider video:', error);
        }
    };


    const handleVideoChange = (e) => {
        const files = e.target.files;
        setSelectedVideos(files);

        if (files.length > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setVideoPreview(reader.result);
            };
            reader.readAsDataURL(files[0]);
        } else {
            setVideoPreview(null);
        }
    };

    const handleVideoUpload = async () => {
        try {
            const formData = new FormData();
            for (const video of selectedVideos) {
                formData.append('testimonialSliderVideo', video);
            }

            const response = await fetch('https://fitandfamousshowbackend.evertizsolutions.com/api/upload-testimonial-slider-video', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                alert('Testimonial slider videos uploaded successfully!');
                console.log(result);
                window.location.reload();
            } else {
                const errorResult = await response.json();
                alert(`Error uploading testimonial slider videos: ${errorResult.message}`);
            }
        } catch (error) {
            console.error('Error uploading testimonial slider videos:', error);
            alert('Internal Server Error');
        }
    };

    const handleVideoDelete = async (index) => {
        try {
            // Ask for confirmation before deleting the video
            const confirmed = window.confirm('Are you sure you want to delete this video?');

            if (confirmed) {
                const response = await axios.delete(`https://fitandfamousshowbackend.evertizsolutions.com/api/delete-testimonial-slider-video/${index}`);
                if (response.status === 200) {
                    alert('Testimonial slider video deleted successfully');
                    // Refresh hero slider videos after deletion
                    window.location.reload();
                } else {
                    console.error('Error deleting testimonial slider video:', response.data);
                    alert('Error deleting testimonial slider video');
                }
            }
        } catch (error) {
            console.error('Error deleting testimonial slider video:', error);
            alert('Error deleting testimonial slider video');
        }
    };

    return (
        <div className='hero-slider-videos-wrapper'>
            <h3>Testimonial Slider Videos</h3>
            <br />
            {testimonialSliderVideos.length === 0 ? (
                <p>No testimonial slider videos found</p>
            ) : (
                <Swiper className='hero-section'
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                >
                    {testimonialSliderVideos.map((testimonialSlideVideo, index) => (
                        <SwiperSlide key={index}>
                            <div className='heroSlides-wrapper'>
                                <video controls>
                                    <source src={testimonialSlideVideo} />
                                </video>
                                <button className='btn-inverse' onClick={() => handleVideoEdit(index)}>Edit Video</button><br />
                                <button className='btn-inverse' onClick={() => handleVideoDelete(index)}>Delete Video</button>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
            <br />

            <input type="file" name="TestimonialSliderVideo" accept="video/*" onChange={handleVideoChange} />
            <button className='btn-inverse' type="button" onClick={handleVideoUpload}>Insert new video</button><br />


        </div>
    );
};

export default TestimonialSliderVideos;