import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './homepage.css';
import Marquee from "react-fast-marquee";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, FreeMode, Navigation, Autoplay } from 'swiper/modules';
import shuffle from 'lodash/shuffle';

const HomePage = () => {
  const [heroSliderImages, setHeroSliderImages] = useState([]);
  const [testimonialSliderImages, setTestimonialSliderImages] = useState([]);
  const [testimonialSliderVideos, setTestimonialSliderVideos] = useState([]);
  const [homepageMarqueeImages, setHomepageMarqueeImages] = useState([]);
  const [error, setError] = useState(null);
  const [videoPlayingStates, setVideoPlayingStates] = useState([]);
  const [contestants, setContestants] = useState([]);
  const fetchContestants = async () => {
    try {
      const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/contestants');
      setContestants(shuffle(response.data.contestants));
    } catch (error) {
      console.error('Error fetching contestants:', error);
    }
  };
  useEffect(() => {
    const fetchHeroSliderImages = async () => {
      try {
        const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/get-hero-slider-images');
        const heroSliderImagesData = response.data;

        if (heroSliderImagesData && heroSliderImagesData.heroSliderImageUrl) {
          // Use heroSliderImagesData.heroSliderImageUrl directly
          setHeroSliderImages(heroSliderImagesData.heroSliderImageUrl);
        } else {
          console.error('Invalid or missing heroSliderImageUrl data in the API response:', heroSliderImagesData);
          setError('Error fetching hero slider images');
        }
      } catch (error) {
        console.error('Error fetching hero slider images:', error);
        setError('Error fetching hero slider images');
      }
    };
    const fetchTestimonialSliderImages = async () => {
      try {
        const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/get-testimonial-slider-images');
        const testimonialSliderImagesData = response.data;

        if (testimonialSliderImagesData && testimonialSliderImagesData.testimonialSliderImageUrl) {

          setTestimonialSliderImages(testimonialSliderImagesData.testimonialSliderImageUrl);
        } else {
          console.error('Invalid or missing testimonialSliderImageUrl data in the API response:', testimonialSliderImagesData);
          setError('Error fetching testimonial slider images');
        }
      } catch (error) {
        console.error('Error fetching testimonial slider images:', error);
        setError('Error fetching testimonial slider images');
      }
    };
    const fetchTestimonialSliderVideos = async () => {
      try {
        const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/get-testimonial-slider-videos');
        const testimonialSliderVideosData = response.data;

        if (testimonialSliderVideosData && testimonialSliderVideosData.testimonialSliderVideoUrl) {

          setTestimonialSliderVideos(testimonialSliderVideosData.testimonialSliderVideoUrl);
          setVideoPlayingStates(new Array(testimonialSliderVideosData.length).fill(false));
        } else {
          console.error('Invalid or missing testimonialSliderVideoUrl data in the API response:', testimonialSliderVideosData);
          setError('Error fetching testimonial slider videos');
        }
      } catch (error) {
        console.error('Error fetching testimonial slider videos:', error);
        setError('Error fetching testimonial slider videos');
      }
    };

    const fetchHomepageMarqueeImages = async () => {
      try {
        const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/get-homepage-marquee-images');
        const homepageMarqueeImagesData = response.data;

        if (homepageMarqueeImagesData && homepageMarqueeImagesData.homepageMarqueeImageUrl) {

          setHomepageMarqueeImages(homepageMarqueeImagesData.homepageMarqueeImageUrl);
        } else {
          console.error('Invalid or missing homepageMarqueeImageUrl data in the API response:', homepageMarqueeImagesData);
          setError('Error fetching homepageMarquee slider images');
        }
      } catch (error) {
        console.error('Error fetching homepageMarquee slider images:', error);
        setError('Error fetching homepageMarquee slider images');
      }
    };
    fetchTestimonialSliderImages();
    fetchTestimonialSliderVideos();
    fetchHomepageMarqueeImages();
    fetchContestants();
    fetchHeroSliderImages();
  }, []);

  // Toggle video playing state for individual video and reset others
  const toggleVideoPlaying = (index) => {
    setVideoPlayingStates(prevStates => {
      const newStates = new Array(prevStates.length).fill(false);
      newStates[index] = true;
      return newStates;
    });

    const videos = document.querySelectorAll('.testimonial-video'); // Get all video elements
    videos.forEach((video, i) => {
      if (i !== index) {
        video.pause(); // Pause other videos
      }
    });

    const videoToPlay = videos[index];
    videoToPlay.play(); // Play the clicked video
  };

  return (
    <div className='homepage-wrapper'>
      <Marquee autoFill="true">
        {homepageMarqueeImages.map((homepageMarqueeImage, index) => (
          <div className='marquee-wrapper' key={index}>
            <img src={homepageMarqueeImage} alt='slide_image' />
          </div>
        ))}
      </Marquee>

      <Swiper className='hero-section'
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
      >
        {heroSliderImages.map((heroSlideImage, index) => (
          <SwiperSlide>
            <div className='heroSlides-wrapper' key={index}>
              <img src={heroSlideImage} alt='slide_image' />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>


      <div className='categories-section-wrapper'>
        <h3>Browse through categories</h3>
        <div className='categories-wrapper'>
          <Link to={`/category/all-contestants`}>
            <button > All Contestants </button>
          </Link>
          <div className='double-categories'>
            <Link to={`/category/mens-physique`}>
              <button> Men's Phyisque </button>
            </Link>
            <Link to={`/category/mens-bodybuilding`}>
              <button> Men's Bodybuilding </button>
            </Link>
          </div>
          <div className='double-categories'>
            <Link to={`/category/bikini-model`}>
              <button> Bikini Model </button>
            </Link>
            <Link to={`/category/transformation`}>
              <button> Transformation </button>
            </Link>
          </div>
        </div>
      </div>

      <div className='contestantsPage-wrapper'>
        <ul className='contestants-wrapper'>
          {contestants.slice(0, 6).map((contestant) => (
            <li className='contestant-card' key={contestant._id}>
              <Link className='relative' to={`/contestant-profile/${contestant._id}`}>
                <img src={contestant.profileImageUrl} className='contestant-profileImage' alt={contestant.name} />
                <p>{contestant.name}</p>
                <button><img src='/assets/heart.svg' /> <span>{contestant.votes}</span> </button>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className='testimonials-section'>
        <h3>Testimonials</h3>

        <Swiper
          slidesPerView={2}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            750: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 2,
            },
          }}
          style={{
            '--swiper-navigation-color': '#fff',
          }}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
        >

          {testimonialSliderImages.map((testimonialSlideImage, index) => (
            <SwiperSlide className='testimonials-wrapper'>
              <div className='testimonial' key={index}>
                <img src={testimonialSlideImage} alt='slide_image' />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          slidesPerView={2}
          freeMode={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            450: {
              slidesPerView: 2,
            },
            750: {
              slidesPerView: 3,
            },
            1300: {
              slidesPerView: 4,
            },
          }}
          style={{
            '--swiper-navigation-color': '#fff',
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, FreeMode]}
        >
          {testimonialSliderVideos.map((testimonialSlideVideo, index) => (
            <SwiperSlide className='testimonials-wrapper'>
              <div className='testimonial' key={index}>
                {videoPlayingStates[index] ? null : (
                  <img className='play-btn' src='assets/play.png' onClick={() => toggleVideoPlaying(index)} />
                )}
                <video className='testimonial-video' controls={videoPlayingStates[index]}>
                  <source src={testimonialSlideVideo} />
                </video>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HomePage;
