import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination} from 'swiper/modules';

const SignupMarqueeImages = () => {
    const [signupMarqueeImages, setSignupMarqueeImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagePreview, setImagePreview] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSignupMarqueeImages = async () => {
            try {
                const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/get-signup-marquee-images');
                const signupMarqueeImagesData = response.data;

                if (signupMarqueeImagesData && signupMarqueeImagesData.signupMarqueeImageUrl) {
                    setSignupMarqueeImages(signupMarqueeImagesData.signupMarqueeImageUrl);
                } else {
                    console.error('Invalid or missing signupMarqueeImageUrl data in the API response:', signupMarqueeImagesData);
                    setError('Error fetching signupMarquee images');
                }
            } catch (error) {
                console.error('Error fetching signupMarquee images:', error);
                setError('Error fetching signupMarquee images');
            }
        };

        fetchSignupMarqueeImages();
    }, []);

    const handleImageEdit = async (index) => {
        try {
            const editFileInput = document.createElement('input');
            editFileInput.type = 'file';
            editFileInput.accept = 'image/*';
    
            editFileInput.onchange = async (event) => {
                const confirmed = window.confirm('Are you sure you want to edit this image?');
    
                if (confirmed) {
                    const file = event.target.files[0];
                    if (file) {
                        const formData = new FormData();
                        formData.append('signupMarqueeImage', file);
    
                        const response = await axios.put(`https://fitandfamousshowbackend.evertizsolutions.com/api/update-signup-marquee-image/${index}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });
    
                        if (response.status === 200) {
                            const { signupMarqueeImageUrl } = response.data;
                            const updatedImages = [...signupMarqueeImages];
                            updatedImages[index] = signupMarqueeImageUrl;
                            setSignupMarqueeImages(updatedImages);
                        } else {
                            console.error('Error updating signupMarquee image:', response.data);
                        }
                    }
                }
            };
    
            // Click the edit file input programmatically
            editFileInput.click();
        } catch (error) {
            console.error('Error editing signupMarquee image:', error);
        }
    };
    

    const handleImageChange = (e) => {
        const files = e.target.files;
        setSelectedImages(files);

        if (files.length > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(files[0]);
        } else {
            setImagePreview(null);
        }
    };

    const handleImageUpload = async () => {
        try {
            const formData = new FormData();
            for (const image of selectedImages) {
                formData.append('signupMarqueeImage', image);
            }

            const response = await fetch('https://fitandfamousshowbackend.evertizsolutions.com/api/upload-signup-marquee-image', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                alert('signupMarquee images uploaded successfully!');
                console.log(result);
                window.location.reload();
            } else {
                const errorResult = await response.json();
                alert(`Error uploading signupMarquee images: ${errorResult.message}`);
            }
        } catch (error) {
            console.error('Error uploading signupMarquee images:', error);
            alert('Internal Server Error');
        }
    };

    const handleImageDelete = async (index) => {
        try {
            // Ask for confirmation before deleting the image
            const confirmed = window.confirm('Are you sure you want to delete this image?');
    
            if (confirmed) {
                const response = await axios.delete(`https://fitandfamousshowbackend.evertizsolutions.com/api/delete-signup-marquee-image/${index}`);
                if (response.status === 200) {
                    alert('signupMarquee image deleted successfully');
                    // Refresh signupMarquee images after deletion
                    window.location.reload();
                } else {
                    console.error('Error deleting signupMarquee image:', response.data);
                    alert('Error deleting signupMarquee image');
                }
            }
        } catch (error) {
            console.error('Error deleting signupMarquee image:', error);
            alert('Error deleting signupMarquee image');
        }
    };

    return (
        <div className='hero-slider-images-wrapper'>
            <h3>Signup Marquee Slider Images</h3>
            <br />
            {signupMarqueeImages.length === 0 ? (
            <p>No signup marquee images found</p>
        ) : (
            <Swiper className='hero-section'
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
            >
                {signupMarqueeImages.map((signupMarqueeImage, index) => (
                    <SwiperSlide key={index}>
                        <div className='heroSlides-wrapper'>
                            <img src={signupMarqueeImage} alt='slide_image' />
                            <button className='btn-inverse' onClick={() => handleImageEdit(index)}>Edit Image</button><br />
                            <button className='btn-inverse' onClick={() => handleImageDelete(index)}>Delete Image</button>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        )}
            <br />
            {imagePreview && (
                <div>
                    <h3>Image Preview</h3>
                    <img src={imagePreview} alt="Selected Preview" style={{ maxWidth: '100%', maxHeight: 'auto' }} />
                </div>
            )}
            
            <input type="file" name="heroSliderImage" accept="image/*" onChange={handleImageChange} />
            <button className='btn-inverse' type="button" onClick={handleImageUpload}>Insert new image</button><br />
            

        </div>
    );
};

export default SignupMarqueeImages;