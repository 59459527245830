import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination} from 'swiper/modules';

const HomepageMarqueeImages = () => {
    const [homepageMarqueeImages, setHomepageMarqueeImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagePreview, setImagePreview] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHomepageMarqueeImages = async () => {
            try {
                const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/get-homepage-marquee-images');
                const homepageMarqueeImagesData = response.data;

                if (homepageMarqueeImagesData && homepageMarqueeImagesData.homepageMarqueeImageUrl) {
                    setHomepageMarqueeImages(homepageMarqueeImagesData.homepageMarqueeImageUrl);
                } else {
                    console.error('Invalid or missing homepageMarqueeImageUrl data in the API response:', homepageMarqueeImagesData);
                    setError('Error fetching homepageMarquee images');
                }
            } catch (error) {
                console.error('Error fetching homepageMarquee images:', error);
                setError('Error fetching homepageMarquee images');
            }
        };

        fetchHomepageMarqueeImages();
    }, []);

    const handleImageEdit = async (index) => {
        try {
            const editFileInput = document.createElement('input');
            editFileInput.type = 'file';
            editFileInput.accept = 'image/*';
    
            editFileInput.onchange = async (event) => {
                const confirmed = window.confirm('Are you sure you want to edit this image?');
    
                if (confirmed) {
                    const file = event.target.files[0];
                    if (file) {
                        const formData = new FormData();
                        formData.append('marqueeImage', file);
    
                        const response = await axios.put(`https://fitandfamousshowbackend.evertizsolutions.com/api/update-homepage-marquee-image/${index}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });
    
                        if (response.status === 200) {
                            const { homepageMarqueeImageUrl } = response.data;
                            const updatedImages = [...homepageMarqueeImages];
                            updatedImages[index] = homepageMarqueeImageUrl;
                            setHomepageMarqueeImages(updatedImages);
                        } else {
                            console.error('Error updating homepageMarquee image:', response.data);
                        }
                    }
                }
            };
    
            // Click the edit file input programmatically
            editFileInput.click();
        } catch (error) {
            console.error('Error editing homepageMarquee image:', error);
        }
    };
    

    const handleImageChange = (e) => {
        const files = e.target.files;
        setSelectedImages(files);

        if (files.length > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(files[0]);
        } else {
            setImagePreview(null);
        }
    };

    const handleImageUpload = async () => {
        try {
            const formData = new FormData();
            for (const image of selectedImages) {
                formData.append('homepageMarqueeImage', image);
            }

            const response = await fetch('https://fitandfamousshowbackend.evertizsolutions.com/api/upload-homepage-marquee-image', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                alert('homepageMarquee images uploaded successfully!');
                console.log(result);
                window.location.reload();
            } else {
                const errorResult = await response.json();
                alert(`Error uploading homepageMarquee images: ${errorResult.message}`);
            }
        } catch (error) {
            console.error('Error uploading homepageMarquee images:', error);
            alert('Internal Server Error');
        }
    };

    const handleImageDelete = async (index) => {
        try {
            // Ask for confirmation before deleting the image
            const confirmed = window.confirm('Are you sure you want to delete this image?');
    
            if (confirmed) {
                const response = await axios.delete(`https://fitandfamousshowbackend.evertizsolutions.com/api/delete-homepage-marquee-image/${index}`);
                if (response.status === 200) {
                    alert('homepageMarquee image deleted successfully');
                    // Refresh homepageMarquee images after deletion
                    window.location.reload();
                } else {
                    console.error('Error deleting homepageMarquee image:', response.data);
                    alert('Error deleting homepageMarquee image');
                }
            }
        } catch (error) {
            console.error('Error deleting homepageMarquee image:', error);
            alert('Error deleting homepageMarquee image');
        }
    };

    return (
        <div className='hero-slider-images-wrapper'>
            <h3>Homepage Marquee Slider Images</h3>
            <br />
            {homepageMarqueeImages.length === 0 ? (
            <p>No homepage marquee images found</p>
        ) : (
            <Swiper className='hero-section'
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
            >
                {homepageMarqueeImages.map((homepageMarqueeImage, index) => (
                    <SwiperSlide key={index}>
                        <div className='heroSlides-wrapper'>
                            <img src={homepageMarqueeImage} alt='slide_image' />
                            <button className='btn-inverse' onClick={() => handleImageEdit(index)}>Edit Image</button><br />
                            <button className='btn-inverse' onClick={() => handleImageDelete(index)}>Delete Image</button>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        )}
            <br />
            {imagePreview && (
                <div>
                    <h3>Image Preview</h3>
                    <img src={imagePreview} alt="Selected Preview" style={{ maxWidth: '100%', maxHeight: 'auto' }} />
                </div>
            )}
            
            <input type="file" name="heroSliderImage" accept="image/*" onChange={handleImageChange} />
            <button className='btn-inverse' type="button" onClick={handleImageUpload}>Insert new image</button><br />
            

        </div>
    );
};

export default HomepageMarqueeImages;