// Signup.js
import React, { useState, useEffect } from 'react';
import Marquee from "react-fast-marquee";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './signup.css';

const Signup = () => {
  const navigate = useNavigate();
  const [signupMarqueeImages, setSignupMarqueeImages] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchSignupMarqueeImages = async () => {
      try {
        const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/get-signup-marquee-images');
        const signupMarqueeImagesData = response.data;

        if (signupMarqueeImagesData && signupMarqueeImagesData.signupMarqueeImageUrl) {

          setSignupMarqueeImages(signupMarqueeImagesData.signupMarqueeImageUrl);
        } else {
          console.error('Invalid or missing signupMarqueeImageUrl data in the API response:', signupMarqueeImagesData);
          setError('Error fetching signupMarquee slider images');
        }
      } catch (error) {
        console.error('Error fetching signupMarquee slider images:', error);
        setError('Error fetching signupMarquee slider images');
      }
    };
    fetchSignupMarqueeImages();
  }, []);

  const [formData, setFormData] = useState({
    username: '',
    password: '',
    userType: 'voter',
    email: '',
    name: '',
    age: ''
  });
  const [emailVerified, setEmailVerified] = useState(false);
  const senderEmail = 'om.kanbi@evertiz.com'; // Fixed sender email address
  const [otp, setOtp] = useState('');
  const [otpInput, setOtpInput] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otpButtonVerified, setOtpButtonVerified] = useState(false);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const generateOTP = async () => {
    try {
      handleEmailVerification();
    } catch (error) {
      console.error('Error generating OTP:', error);
      return;
    }
  };

  const handleEmailVerification = async () => {
    try {
      // Generate a random 6-digit OTP
      const generatedOTP = Math.floor(100000 + Math.random() * 900000);
      setOtp(generatedOTP.toString());

      const response = await axios.post('https://fitandfamousshowbackend.evertizsolutions.com/send-email', {
        from: senderEmail, // Fixed sender email address
        to: formData.email,
        subject: 'Email Verification',
        text: `Please use the following OTP to verify your email: ${generatedOTP}`,
      });

      if (response.status === 200) {
        alert('Verification email sent. Please check your inbox.');
        setShowOtpInput(true);
      } else {
        throw new Error('Failed to send verification email.');
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const usernameExistsResponse = await fetch('https://fitandfamousshowbackend.evertizsolutions.com/api/username-exists', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: formData.username }),
      });

      // Basic email format validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        alert('Please enter a valid email address.');
        return;
      }

      if (!usernameExistsResponse.ok) {
        throw new Error('Failed to check username existence');
      }

      const { exists } = await usernameExistsResponse.json();

      if (exists) {
        alert('Username already exists. Please choose a different username.');
        return;
      }

      // Validation for username and password length
      if (formData.username.length < 5) {
        alert('Username should be at least 5 characters long.');
        return;
      }

      // Password complexity validation using regular expressions
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.*[a-zA-Z]).{8,}$/;
      if (!passwordRegex.test(formData.password)) {
        alert('Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.');
        return;
      }

      const response = await fetch('https://fitandfamousshowbackend.evertizsolutions.com/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Signup successful. You can now login.');
        navigate('/login');
      } else {
        console.error('Signup failed');
      }
    } catch (error) {
      console.error('Error during signup:', error);
    }
  };

  const handleOtpCheck = () => {
    if (otp === otpInput) {
      alert('OTP is correct. Email verified!');
      setEmailVerified(true);
      setOtpButtonVerified(true);
    } else {
      alert('Incorrect OTP. Please try again.');
    }
  };


  return (<>
  <Marquee autoFill="true">
        {signupMarqueeImages.map((signupMarqueeImage, index) => (
          <div className='marquee-wrapper' key={index}>
            <img src={signupMarqueeImage} alt='slide_image' />
          </div>
        ))}
      </Marquee>
    <div className='signup-form-wrapper'>
        
      <h3>Signup Form</h3>
      <form onSubmit={handleSubmit}>

        <label htmlFor="username">Username:</label>
        <input type="text" id="username" name="username" value={formData.username} onChange={handleInputChange} required />

        <label htmlFor="password">Password:</label>
        <input type="password" id="password" name="password" value={formData.password} onChange={handleInputChange} required />

        <label htmlFor="userType">Select User Type:</label>
        <select id="userType" name="userType" value={formData.userType} onChange={handleInputChange}>
          <option value="voter">Voter</option>
        </select>

        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} required />

        <button type="button" onClick={generateOTP} style={{ display: showOtpInput ? 'none' : 'block' }}>
          Send Verification Email
        </button>

        {showOtpInput && (
          <div>
            {!otpButtonVerified && (
              <div>
                <label htmlFor="otpInput">Enter OTP:</label>
                <input type="number" id="otpInput" name="otpInput" onChange={(e) => setOtpInput(e.target.value)} required />
              </div>
            )}
            <button
              type="button"
              onClick={handleOtpCheck}
              disabled={otpButtonVerified} // Disable the button if otpButtonVerified is true
              style={{ color: otpButtonVerified ? '#4caf50' : 'initial' }}
            >
              {otpButtonVerified ? 'Verified' : 'Check OTP'}
            </button>
          </div>
        )}

        <label htmlFor="name">Voter Name:</label>
        <input type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} required />

        <label htmlFor="age">Age:</label>
        <input type="number" id="age" name="age" value={formData.age} onChange={handleInputChange} />

        {!emailVerified && (
          <button type="button" onClick={() => alert('Please verify your email address.')}>
            Submit
          </button>
        )}

        {emailVerified && (
          <button type="submit">
            Submit
          </button>
        )}
      </form>
    </div></>
  );
};


export default Signup;