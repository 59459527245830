import React from 'react';
import { Link } from 'react-router-dom';
import Search from './Search';

const Navigation = ({ isLoggedIn, onLogout }) => {


  return (
      <nav>
        <div className='search-wrapper-desktop'><Search /></div>
          {isLoggedIn && (
            <>
              <Link to="/"><button className='primary-button'>Home</button></Link>
              <Link to="/register"><button className='primary-button'>Register</button></Link>
              <Link to="/dashboard"><button className='primary-button'>Dashboard</button></Link>
              <Link to="/"><button className='primary-button' onClick={onLogout}>Logout</button></Link>
            </>
          )}
          {!isLoggedIn && (
            <>
              <Link to="/"><button>Home</button></Link>
              <Link to="/register"><button>Register</button></Link>
              <Link to="/login"><button>Login</button></Link>
            </>
          )}
          
      </nav>
  );
};

export default Navigation;
