import React, { useState } from 'react';
import axios from 'axios';
import './login.css';
import { Link, useNavigate } from 'react-router-dom';

const Login = ({ onLogin, isLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // Check if the username exists
      const usernameExistsResponse = await axios.post('https://fitandfamousshowbackend.evertizsolutions.com/api/username-exists', {
        username,
      });
  
      if (!usernameExistsResponse.data.exists) {
        // If username does not exist, show an alert
        alert('Username does not exist!');
        return;
      }
  
      // If the username exists, proceed with login
      const loginResponse = await axios.post('https://fitandfamousshowbackend.evertizsolutions.com/api/auth/login', {
        username,
        password,
      });
  
      const { token, userType, userId } = loginResponse.data;
      onLogin({ token, userType, userId });
  
      navigate('/dashboard');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // If unauthorized, it means login failed due to incorrect password
        alert('Please check your password and try again.');
      } else {
        // Handle other errors
        console.error('Login failed:', error.response.data.message);
      }
    }
  };
  

  return (
    <div className='login-form-wrapper'>
      {isLoggedIn ? (
        <p>You are already logged in. Please logout before attempting to login again.</p>
      ) : (
        <>
          <h3>Login</h3>
          <div>
            <label>Username: </label>
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </div>
          <div>
            <label>Password: </label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div>
            <button onClick={handleLogin}>Login</button>
          </div>
          <p>
            Haven't signed up yet? <Link to="/signup">Signup here</Link>
          </p>
        </>
      )}
    </div>
  );
};

export default Login;
