import React, { useEffect, useState, useRef } from 'react';
import Marquee from "react-fast-marquee";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './registration.css';

const Registration = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [signupMarqueeImages, setSignupMarqueeImages] = useState([]);
  useEffect(() => {
    const fetchSignupMarqueeImages = async () => {
      try {
        const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/get-signup-marquee-images');
        const signupMarqueeImagesData = response.data;

        if (signupMarqueeImagesData && signupMarqueeImagesData.signupMarqueeImageUrl) {

          setSignupMarqueeImages(signupMarqueeImagesData.signupMarqueeImageUrl);
        } else {
          console.error('Invalid or missing signupMarqueeImageUrl data in the API response:', signupMarqueeImagesData);
          setError('Error fetching signupMarquee slider images');
        }
      } catch (error) {
        console.error('Error fetching signupMarquee slider images:', error);
        setError('Error fetching signupMarquee slider images');
      }
    };
    fetchSignupMarqueeImages();
  }, []);

  const [formData, setFormData] = useState({
    userType: 'contestant',
    name: '',
    age: 0,
    weight: 0,
    category: '',
    instagramProfileLink: '',
    contest: '',
  });

  const [selectedProfileImage, setSelectedProfileImage] = useState(null);
  const [selectedPostImages, setSelectedPostImages] = useState(Array(5).fill(null));
  const [categories, setCategories] = useState([]);

  const [contests, setContests] = useState([]);

  useEffect(() => {
    fetchContestData(); // Initial fetch
  }, []);

  // const fetchContestData = () => {
  //   const currentTime = new Date(); // Get the current time

  //   axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/contests')
  //     .then(response => {
  //       const filteredContests = response.data.filter(contest => {
  //         return new Date(contest.startDateTime) <= currentTime; // Filter contests based on startDateTime
  //       });

  //       const uniqueCategories = [...new Set(filteredContests.map(contest => contest.category))];
  //       setCategories(uniqueCategories);
  //       setContests(filteredContests);
  //     })
  //     .catch(error => console.error('Error fetching categories:', error));
  // };

  const fetchContestData = () => {
    const currentTime = new Date(); // Get the current time
  
    axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/contests')
      .then(response => {
        const filteredContests = response.data.filter(contest => {
          return new Date(contest.startDateTime) <= currentTime; // Filter contests based on startDateTime
        });
  
        const uniqueCategories = [...new Set(filteredContests.map(contest => contest.category))];
        setCategories(uniqueCategories);
        setContests(filteredContests);
      })
      .catch(error => console.error('Error fetching contests:', error));
  };
  

// Inside the handleInputChange function:
const handleInputChange = (e) => {
  const { name, value } = e.target;

  // Check if the input field is 'category'
  if (name === 'category') {
    // Find the corresponding contest based on the selected category
    const selectedContest = contests.find(contest => contest._id === value);

    setFormData({
      ...formData,
      category: selectedContest ? selectedContest.category : '', // Set category to selected contest's category
      contest: selectedContest ? selectedContest._id : null,
    });

    // Log the selected contest ID and category to verify
    console.log('Selected Contest ID:', selectedContest ? selectedContest._id : null);
    console.log('Selected Contest Category:', selectedContest ? selectedContest.category : null);
  } else {
    setFormData({
      ...formData,
      [name]: value,
    });
  }
};


  const handleInstagramUsernameChange = (e) => {
    const username = e.target.value;

    setFormData((prevData) => ({
      ...prevData,
      instagramProfileLink: username, // Set only the username
    }));
  };

  const MAX_IMAGE_SIZE_MB = 5;
  const MAX_IMAGE_SIZE_BYTES = MAX_IMAGE_SIZE_MB * 1024 * 1024; // Convert MB to bytes
  
  const profileImageInputRef = useRef(null); // Create a ref for profile image input
  const postImageInputRefs = useRef(Array(4).fill(null).map(() => React.createRef())); // Create refs for post image inputs

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > MAX_IMAGE_SIZE_BYTES) {
      alert('Profile image size exceeds 5MB limit. Please select a smaller image.');
      setSelectedProfileImage(null);
      profileImageInputRef.current.value = ''; // Clear file input value
      return;
    }
    setSelectedProfileImage(file);
  };

  const handlePostImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file && file.size > MAX_IMAGE_SIZE_BYTES) {
      alert(`Post image ${index + 1} size exceeds 5MB limit. Please select a smaller image.`);
      setSelectedPostImages((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = null;
        return newImages;
      });
      postImageInputRefs.current[index].current.value = ''; // Clear file input value
      return;
    }
    setSelectedPostImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index] = file;
      return newImages;
    });
  };
  

  const uploadProfileImage = async (userId) => {
    try {
      const formData = new FormData();
      formData.append('profileImage', selectedProfileImage);

      const response = await fetch(`https://fitandfamousshowbackend.evertizsolutions.com/api/upload-profile-image/${userId}`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Profile image uploaded successfully:', data.profileImageUrl);
      } else {
        console.error('Profile image upload failed');
      }
    } catch (error) {
      console.error('Error uploading profile image:', error);
    }
  };

  const uploadPostImages = async (userId) => {
    try {
      for (let i = 0; i < 4; i++) {
        const formData = new FormData();
        formData.append('postImage', selectedPostImages[i]);

        const response = await fetch(`https://fitandfamousshowbackend.evertizsolutions.com/api/upload-post-image/${userId}`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          console.log(`Post image ${i + 1} uploaded successfully:`, data.postImageUrl);
        } else {
          console.error(`Post image ${i + 1} upload failed`);
        }
      }

      console.log('All post images uploaded successfully');
    } catch (error) {
      console.error('Error uploading post images:', error);
    }
  };

  const handleSubmit = async () => {
    // e.preventDefault();
    // Construct the full Instagram link here before sending it to the server
    const fullLink = formData.instagramProfileLink
      ? `https://www.instagram.com/${formData.instagramProfileLink}`
      : '';

    try {
      const response = await fetch('https://fitandfamousshowbackend.evertizsolutions.com/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          instagramProfileLink: fullLink, // Send the full link to the server
        }),
      });

      if (response.ok) {
        const { user } = await response.json();
        const userId = user._id;

        localStorage.setItem('userId', userId);

        if (userId && selectedProfileImage) {
          await uploadProfileImage(userId);
        }

        if (userId && selectedPostImages.some((image) => image !== null)) {
          await uploadPostImages(userId);
        }

        navigate(`/contestant-profile/${userId}`);
      } else {
        console.error('Registration failed');
      }
    } catch (error) {
      console.error('Error during registration:', error);
    }
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(e) {
    e.preventDefault();
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const result = await axios.post("https://fitandfamousshowbackend.evertizsolutions.com/orders");

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: "rzp_test_dt8ARo16LbgcBt", // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Fit and Famous",
      description: "Test Transaction",
      image: "./assets/FitAndFamous_Logo.png",
      order_id: order_id,
      handler: handleSubmit,
      prefill: {
        name: "Test User",
        email: "Test@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Fit and Famous Show",
      },
      theme: {
        color: "#0099ff",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }


  return (<>
    <Marquee autoFill="true">
          {signupMarqueeImages.map((signupMarqueeImage, index) => (
            <div className='marquee-wrapper' key={index}>
              <img src={signupMarqueeImage} alt='slide_image' />
            </div>
          ))}
        </Marquee>
    <div className='registration-form-wrapper'>
      <h3>Registration Form</h3>
      <form onSubmit={displayRazorpay}>
        <label htmlFor="userType">Select User Type:</label>
        <select id="userType" name="userType" value={formData.userType} onChange={handleInputChange}>
          <option value="contestant">Contestant</option>
        </select>

        {/* Contestant-specific fields */}
        <label htmlFor="name">Contestant Name:</label>
        <input type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} required />

        <label htmlFor="age">Age:</label>
        <input type="number" id="age" name="age" value={formData.age} onChange={handleInputChange} required />

        <label htmlFor="weight">Weight (Kg):</label>
        <input type="number" id="weight" name="weight" value={formData.weight} onChange={handleInputChange} required />

        <label htmlFor="category">Category:</label>
        <select id="category" name="category" value={formData.category} onChange={handleInputChange} required>
          <option value="" disabled>Select a category</option>
          {contests.map((contest, index) => (
            <option key={index} value={contest._id}>{contest.category}</option>
          ))}
        </select>
        <br /><br />

        {/* Profile image upload field */}
        <label htmlFor="profileImage">Select Profile Image:</label><br /><br />
        <div className='profileImage'>
        <input ref={profileImageInputRef} type="file" id="profileImage" name="profileImage" accept="image/*" onChange={handleProfileImageChange} required />

          {/* Display selected profile image */}
          {selectedProfileImage && (
            <div>
              <img src={URL.createObjectURL(selectedProfileImage)} alt="Selected" />
            </div>
          )}
        </div>

        {/* Post images upload field */}
        <br /><p>Select 4 Post Images:</p><br />
        <div className='postImages-wrapper'>
          {[0, 1, 2, 3].map((index) => (
            <div className='postImages' key={index}>
              <input ref={postImageInputRefs.current[index]} type="file" id={`postImage${index}`} name={`postImage${index}`} accept="image/*" onChange={(e) => handlePostImageChange(e, index)} required />
              {selectedPostImages[index] && (
                <div className='post'>
                  {/* <p>{`Selected Post Image ${index + 1}:`}</p> */}
                  <img
                    src={URL.createObjectURL(selectedPostImages[index])}
                    alt={`Selected ${index + 1}`}
                  />
                </div>
              )}
            </div>
          ))}

        </div><br />
        {/* New field for Instagram username */}
        <label htmlFor="instagramProfileLink">Instagram Username:</label>
        <div className='instagramProfileLink'>
          <p>https://www.instagram.com/</p>
          <input type="text" id="instagramProfileLink" name="instagramProfileLink" value={formData.instagramProfileLink} onChange={handleInstagramUsernameChange} required />
        </div><br />
        {/* Automatically trigger profile image and post image upload */}
        <button type="submit">Pay & Register</button>
      </form>
    </div></>
  );
};

export default Registration;