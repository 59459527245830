// Dashboard.js
import React from 'react';
import AdminDashboard from './AdminDashboard';
// import HomePage from '../HomePage';
import VoterDashboard from './VoterDashboard';
import './dashboard.css';

const DashboardPage = ({ userType, userId }) => {
  return (
    <div>
      {userType === 'admin' && <AdminDashboard userId={userId} />}
      {/* {userType === 'voter' && <HomePage />} */}
      {userType === 'voter' && <VoterDashboard userId={userId} />}
    </div>
  );
};

export default DashboardPage;
