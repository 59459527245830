import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './postdetails.css';

const PostDetails = ({ userId, userType }) => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [voterDetails, setVoterDetails] = useState({});

  // Function to fetch post details and voter details
  const fetchPostDetails = async () => {
    try {
      const response = await axios.get(`https://fitandfamousshowbackend.evertizsolutions.com/api/post-details/${postId}`);
      setPost(response.data.post);

      // Fetch details of all voters
      const votersResponse = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/voters');
      const voters = votersResponse.data.voters.reduce((acc, voter) => {
        acc[voter._id] = voter;
        return acc;
      }, {});
      setVoterDetails(voters);
    } catch (error) {
      console.error('Error fetching post details:', error);
    }
  };

  useEffect(() => {
    fetchPostDetails();
  }, [postId]);

  // Function to handle posting a comment
  const postComment = async () => {
    try {
      // Check if the user is a voter before posting a comment
      if (userType === 'voter') {
        // Make a POST request to the server to add a comment
        await axios.post(`https://fitandfamousshowbackend.evertizsolutions.com/api/post-comments/${postId}`, {
          text: commentText,
          voterId: userId,
        });

        // Refresh the post details after posting a comment
        fetchPostDetails();
        setCommentText(''); // Clear the comment text input
      } else {
        console.error('Only logged-in voters can post comments.');
      }
    } catch (error) {
      console.error('Error posting comment:', error);
    }
  };

  if (!post) {
    return <p>Loading post details...</p>;
  }

  return (
    <div className='post-details-wrapper'>
      <img src={post.postImageUrl} alt={`Post ${postId}`} />
      <div>
        {/* <p>Likes: {post.likes}</p>
        <p>Caption: {post.caption}</p> */}
        <h3>Comments:</h3>
        <div className='comments-wrapper'>
          {post.comments.map((comment, index) => (
            <div key={index} className='comment-container comment'>
              {voterDetails[comment.voterId] && (
                <p>{voterDetails[comment.voterId].username} : {comment.text}</p>
                // Display other voter details as needed
              )}
            </div>
          ))}
        </div>
        {userType === 'voter' && (
          <div>
            <input
              type='text'
              placeholder='Add a comment...'
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            />
            <button onClick={postComment}>Post Comment</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostDetails;