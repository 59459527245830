import React, { useState, useEffect } from 'react';
import axios from 'axios';

function WinnersList() {
  const [winners, setWinners] = useState([]);

  useEffect(() => {
    fetchWinners();
  }, []);

  const fetchWinners = async () => {
    try {
      const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/winners');
      setWinners(response.data);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching winners:', error);
    }
  };

  const handleDeleteWinner = async (winnerId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this winner?');
    if (!confirmDelete) return; // Do nothing if user cancels deletion

    try {
      await axios.delete(`https://fitandfamousshowbackend.evertizsolutions.com/api/winners/${winnerId}`);
      // Remove the deleted winner from the list
      setWinners(winners.filter(winner => winner._id !== winnerId));
    } catch (error) {
      console.error('Error deleting winner:', error);
    }
  };


  return (
      <div className='winners-wrapper'>
          <h3>Winners List</h3><br />
          {winners.length === 0 ? (
            <a>No Winners found.</a>
          ) : (
            <ul>
              {winners.map(winner => (
                <li key={winner._id}>
                  <p>Name: {winner.winner.name}</p>
                  <p>Age: {winner.winner.age}</p>
                  <p>Weight: {winner.winner.weight}</p>
                  <p>Profile image: <a href={winner.winner.profileImageUrl} target='_blank'>View</a></p>
                  <p>Post images:
                  {winner.winner.posts.map(post => (
                   <a href={post.postImageUrl} target='_blank'> View </a>
                  ))}</p>
                  <p>Instagram: <a href={winner.winner.instagramProfileLink} target='_blank'>View</a></p>
                  <p>{winner.contest.title}</p>
                  <p>Category: {winner.contest.category}</p>
                  <p>Votes: {winner.winner.votes}</p> 
                  <p>Start: {new Date(winner.contest.startDateTime).toLocaleString()}</p>
                  <p>End: {new Date(winner.contest.endDateTime).toLocaleString()}</p>
                  <button className='btn-inverse' onClick={() => handleDeleteWinner(winner._id)}>Delete</button>
                </li>
              ))}
            </ul>
          )}
        </div>
  );
}

export default WinnersList;
