import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchClicked, setIsSearchClicked] = useState(false); // New state

  const searchContestants = async () => {
    // Check if the search term is empty
    if (searchTerm.trim() === '') {
      // If the search term is empty, clear the search results and return
      setSearchResults([]);
      return;
    }
  
    setIsLoading(true);
    setIsSearchClicked(true); // Update isSearchClicked state
    try {
      const response = await axios.get(`https://fitandfamousshowbackend.evertizsolutions.com/api/contestants/search?searchTerm=${searchTerm}`);
      const contestants = response.data;
      setSearchResults(contestants);
    } catch (error) {
      console.error('Error searching contestants:', error);
      // Handle error on the frontend
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  return (
    <div className="search-container">
      {isSearchOpen ? (
        <svg
          className="close-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="40px"
          height="40px"
          onClick={toggleSearch}
        >
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
        </svg>
      ) : (
        <svg
          className="search-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="40px"
          height="40px"
          onClick={toggleSearch}
        >
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        </svg>
      )}
      {isSearchOpen && (
        <div className='search-wrapper'>
          <div className="search-form">
            <input
              className='search-input'
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search contestants"
            />
            <button onClick={searchContestants} className="search-button">Search</button>
          </div>
          {isLoading ? (
            <ul>
              <li><p>Loading...</p></li>
            </ul>
          ) : isSearchClicked && searchResults.length === 0 ? ( // Updated condition
            <ul>
              <li><p>No contestants found.</p></li>
            </ul>
          ) : (
            <ul>
              {searchResults.map((contestant) => (
                <li className='contestant-card' key={contestant._id}>
                  <Link to={`/contestant-profile/${contestant._id}`}>
                    <p>{contestant.name}</p>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default Search;
