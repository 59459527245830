import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import './contestantProfile.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import shuffle from 'lodash/shuffle';
import Lightbox from './Lightbox';

const ContestantProfile = ({ userId, userType }) => {
  const navigate = useNavigate();
  const { contestantId } = useParams();
  const [contestant, setContestant] = useState(null);
  const [posts, setPosts] = useState([]);
  const [hasVoted, setHasVoted] = useState(false);
  const [voteError, setVoteError] = useState(null); // Track vote error
  const [suggestedContestants, setSuggestedContestants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    const fetchContestantProfile = async () => {
      try {
        const responseContestant = await axios.get(`https://fitandfamousshowbackend.evertizsolutions.com/api/user/${contestantId}`);
        setContestant(responseContestant.data.data);

        const responsePosts = await axios.get(`https://fitandfamousshowbackend.evertizsolutions.com/api/posts/${contestantId}`);
        setPosts(responsePosts.data.posts);
      } catch (error) {
        console.error('Error fetching contestant profile:', error);
      }
    };

    fetchContestantProfile();
  }, [contestantId]);

  useEffect(() => {
    const checkVotedStatus = async () => {
      try {
        const response = await axios.get(`https://fitandfamousshowbackend.evertizsolutions.com/api/user/${userId}`);
        const userData = response.data.data;

        setHasVoted(userData.votedContestants.includes(contestantId));
      } catch (error) {
        console.error('Error checking voted status:', error);
      }
    };

    checkVotedStatus();
  }, [userId, contestantId]);
  const handleVote = async () => {
    try {
      // Check if the user is logged in
      if (!userId) {
        console.error('User is not logged in');
        // Show an alert to login
        alert('Please log in to vote.');
        return;
      }

      // Check if the user type is 'voter'
      if (userType !== 'voter') {
        console.error('User is not authorized to vote');
        // Show an alert to login
        alert('Only voters can vote.');
        return;
      }

      // If the user has already voted, remove the vote
      if (hasVoted) {
        // Make a DELETE request to remove the vote
        await axios.delete(`https://fitandfamousshowbackend.evertizsolutions.com/api/voter/${userId}/vote/${contestantId}`);
        setHasVoted(false);
        // Update the vote count by decrementing
        setContestant((prevContestant) => ({
          ...prevContestant,
          votes: prevContestant.votes - 1, // Decrement the vote count
        }));
      } else {
        // User is voting for the first time
        const response = await axios.post(`https://fitandfamousshowbackend.evertizsolutions.com/api/vote/${contestantId}`);
        const updatedVotes = response.data.votes;

        setVoteError(null); // Clear previous error message
        console.log(`Voted successfully! Updated votes count: ${updatedVotes}`);

        // Display the updated votes count
        setContestant((prevContestant) => ({
          ...prevContestant,
          votes: updatedVotes,
        }));

        // Toggle hasVoted state
        setHasVoted(true);

        // Update the vote status in the backend
        await axios.post(`https://fitandfamousshowbackend.evertizsolutions.com/api/voter/${userId}/vote/${contestantId}`);

      }
    } catch (error) {
      console.error('Error voting:', error);
    }
  };

  // Function to handle opening the lightbox
  const openLightbox = (imageUrl) => {
    setSelectedImage(imageUrl);
    setLightboxOpen(true);
  };

  // Function to handle closing the lightbox
  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  // Render the lightbox if it's open
  const renderLightbox = () => {
    if (lightboxOpen) {
      return <Lightbox imageUrl={selectedImage} onClose={closeLightbox} />;
    }
    return null;
  };


  const handleEditVotes = async () => {
    try {
      const newVoteCount = prompt('Enter the new vote count:');

      // Check if the user entered a new vote count
      if (newVoteCount !== null) {
        const confirmation = window.confirm('Are you sure you want to update the vote count?');

        if (confirmation) {
          // Make a PUT request to edit votes
          const response = await axios.put(`https://fitandfamousshowbackend.evertizsolutions.com/api/edit-votes/${contestantId}`, {
            newVoteCount: parseInt(newVoteCount, 10),
          });

          console.log(response.data.message);

          // Manually update the contestant state with the new vote count
          setContestant((prevContestant) => ({
            ...prevContestant,
            votes: response.data.updatedVotes,
          }));
        } else {
          console.log('Edit votes cancelled by the user.');
        }
      }
    } catch (error) {
      console.error('Error editing votes:', error);
    }
  };


  useEffect(() => {
    const fetchSuggestedContestants = async () => {
      try {
        // Fetch the current contestant
        const contestantResponse = await axios.get(`https://fitandfamousshowbackend.evertizsolutions.com/api/user/${contestantId}`);
        const currentContestant = contestantResponse.data.data;

        // Fetch all contestants
        const allContestantsResponse = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/contestants');
        const allContestants = allContestantsResponse.data.contestants;

        // Filter suggested contestants based on the category of the current contestant
        const suggestedContestants = allContestants.filter(contestant => contestant.category === currentContestant.category);

        setSuggestedContestants(shuffle(suggestedContestants));
      } catch (error) {
        console.error('Error fetching suggested contestants:', error);
        setError('Error fetching suggested contestants');
      } finally {
        setLoading(false);
      }
    };

    fetchSuggestedContestants();
  }, [contestantId]);


  if (!contestant) {
    return <p>Loading...</p>;
  }

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: `Check out ${contestant.name}'s profile on Fit & Famous Show`,
          url: window.location.href
        });
      } else {
        // Fallback if Web Share API is not supported
        alert('Web Share API is not supported in your browser.');
      }
    } catch (error) {
      console.error('Error sharing:', error);
      // Handle error if sharing fails
    }
  };


  const handleDeleteContestant = async (contestantId) => {
    try {
      // Display a confirmation dialog before deleting
      const confirmDelete = window.confirm('Are you sure you want to delete this contestant?');

      if (confirmDelete) {
        // Use the same API endpoint for deleting a contestant along with posts and images
        await axios.delete(`https://fitandfamousshowbackend.evertizsolutions.com/api/contestants/${contestantId}`);
        alert("Profile deleted successfully!")
        navigate("/");
      }
    } catch (error) {
      console.error('Error deleting contestant:', error);
    }
  };


  return (
    <div className='contestantProfile-wrapper'>
      <div className='contestant-profileImage-details-wrapper post'>
        <Swiper className='posts-slider'
          style={{
            '--swiper-navigation-color': '#fff',
          }}
          lazy={true}
          navigation={true}
          modules={[Navigation]}
        >
          {posts.map((post) => (
            <SwiperSlide>
              <div className='post' key={post._id} >
                <img src={post.postImageUrl} alt={`Post ${post._id}`} onClick={() => openLightbox(post.postImageUrl)} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {renderLightbox()}
        <button className='vote-img-wrapper' onClick={handleVote}>
          <img className={`heart ${hasVoted ? 'red-heart' : ''}`} src='/assets/heart.svg' alt="Heart Icon" /> {hasVoted ? 'Voted!' : 'Vote'}
        </button>



        <div className='profile-buttons-wrapper'>
          <div className='profile-button'>
            <h4>{contestant.votes}</h4>
            <p>Total Votes</p>
          </div>
          <div className='profile-button'>
            {userType === 'admin' && (
              <button className='edit-votes-button' onClick={handleEditVotes}>
                Edit Votes
              </button>
            )}
            {userType === 'admin' && (
              <button className='edit-votes-button' onClick={() => handleDeleteContestant(contestantId)}>
                Delete Profile
              </button>
            )}
            <a className='' href={contestant.instagramProfileLink} target='_blank'>
              <svg data-v-dcbe541a="" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="ui-svg-inline"><path d="M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z"></path></svg>
              <p>Instagram</p></a>
          </div>
          <div className='profile-button' onClick={handleShare}>
            <svg fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><line fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" x1="22" x2="9.218" y1="3" y2="10.083"></line><polygon fill="none" points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></polygon></svg>
            <p id='share-profile'>Share</p>
          </div>
        </div>
      </div>
      <div className='suggestedContestants-wrapper'>
        <ul className='contestants-wrapper'>
          {/* Filter out the current contestant from the suggested contestants */}
          {suggestedContestants.slice(0, 6).filter(suggestedContestant => suggestedContestant._id !== contestantId).map((suggestedContestant) => (
            <li className='contestant-card' key={suggestedContestant._id}>
              <Link className='relative' to={`/contestant-profile/${suggestedContestant._id}`}>
                <img src={suggestedContestant.profileImageUrl} className='contestant-profileImage' alt={suggestedContestant.name} />
                <p>{suggestedContestant.name}</p>
                <button><img src='/assets/heart.svg' /><span>{suggestedContestant.votes}</span></button>
              </Link>
            </li>
          ))}
        </ul>
      </div>

    </div>
  );
};

export default ContestantProfile;
