import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import shuffle from 'lodash/shuffle';
import './contestants.css';

const MensPhysiqueContestants = () => {
  const [contestants, setContestants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const desiredCategories = ["Men's Physique"];

    const fetchContestants = async () => {
      try {
        const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/contestants');
        const filteredContestants = response.data.contestants.filter(
          (contestant) => desiredCategories.includes(contestant.category)
        );
        setContestants(shuffle(filteredContestants));
      } catch (error) {
        setError('Error fetching contestants');
        console.error('Error fetching contestants:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContestants();
  }, []);

  if (loading) {
    return <a>Loading...</a>;
  }

  if (error) {
    return <a>{error}</a>;
  }

  return (
    <div className='contestantsPage-wrapper'>
      <button className='category-btn'>Men's Physique Contestants</button>
      {contestants.length === 0 ? (
        <a>No contestants found.</a>
      ) : (
        <div>
          <ul className='contestants-wrapper'>
            {contestants.map((contestant) => (
              <li className='contestant-card' key={contestant._id}>
                <Link className='relative' to={`/contestant-profile/${contestant._id}`}>
                  <img src={contestant.profileImageUrl} className='contestant-profileImage' alt={contestant.name} />
                  <p>{contestant.name}</p>
                  <button><img src='/assets/heart.svg'/> <span>{contestant.votes}</span> </button>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MensPhysiqueContestants;
