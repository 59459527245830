// AdminDashboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeroSliderImages from '../HeroSliderImages';
import { Link } from 'react-router-dom';
import ContestManagement from './ContestManagement';
import TestimonialSliderImages from '../TestimonialSliderImages';
import TestimonialSliderVideos from '../TestimonialSliderVideos';
import HomepageMarqueeImages from '../HomepageMarqueeImages';
import SignupMarqueeImages from '../SignupMarqueeImages';
import WinnersList from './Winners';

const AdminDashboard = ({ userId, fetchVisitors }) => {
  const [userData, setUserData] = useState(null);
  const [contestants, setContestants] = useState([]);
  const [visitors, setVisitors] = useState({
    centralValue: 0,
    fluctuationRange: 0,
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://fitandfamousshowbackend.evertizsolutions.com/api/user/${userId}`);
        setUserData(response.data.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchContestants = async () => {
      try {
        const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/contestants');
        setContestants(response.data.contestants);
      } catch (error) {
        console.error('Error fetching contestants:', error);
      }
    };

    const fetchVisitors = async () => {
      try {
        const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/visitors');
        setVisitors(response.data.visitors);
      } catch (error) {
        console.error('Error fetching visitors:', error);
      }
    };

    // Fetch data initially
    fetchUserData();
    fetchContestants();
    fetchVisitors();

  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVisitors({ ...visitors, [name]: parseFloat(value) || 0 });
  };


  const handleUpdateVisitors = async () => {
    try {
      await axios.put('https://fitandfamousshowbackend.evertizsolutions.com/api/visitors', visitors);
      fetchVisitors()
    } catch (error) {
      console.error('Error updating visitors:', error);
    }
  };

  // const handleDeleteContestant = async (contestantId) => {
  //   try {
  //     // Use the same API endpoint for deleting a contestant along with posts and images
  //     await axios.delete(`https://fitandfamousshowbackend.evertizsolutions.com/api/contestants/${contestantId}`);

  //     // After deletion, update the list of contestants
  //     const updatedContestants = contestants.filter((contestant) => contestant._id !== contestantId);
  //     setContestants(updatedContestants);
  //   } catch (error) {
  //     console.error('Error deleting contestant:', error);
  //   }
  // };

  if (!userData) {
    return <p>Loading user data...</p>;
  }

  return (
    <div className='dashboard-wrapper'>
      <div className='profile-details-wrapper'>
      <h3 className='voter-dashboard-heading'>Admin Dashboard</h3>
      <p>Welcome, {userData.name}</p>
      </div>
      <WinnersList />
      <ContestManagement />
      <HeroSliderImages />
      <TestimonialSliderImages />
      <TestimonialSliderVideos />
      <HomepageMarqueeImages />
      <SignupMarqueeImages />
      <div className='visitor-count-section'>
        <h3>Visitor Count</h3>
        <br />
        <label>
          Central Value:
          <input
            type='number'
            name='centralValue'
            value={visitors.centralValue}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Fluctuation Range:
          <input
            type='number'
            name='fluctuationRange'
            value={visitors.fluctuationRange}
            onChange={handleInputChange}
          />
        </label>
        <button className="btn-inverse" onClick={handleUpdateVisitors}>Update Visitor Count</button><br />
      </div>



      {/* <div>
        <h3>Contestants</h3><br />
        {contestants.length === 0 ? (
          <p>No contestants found.</p>
        ) : (
          <div className='contestantsPage-wrapper'>
          <ul className='contestants-wrapper'>
            {contestants.map((contestant) => (
              <li className='contestant-card' key={contestant._id}>
                <Link className='relative' to={`/contestant-profile/${contestant._id}`}>
                  <img src={contestant.profileImageUrl} className='contestant-profileImage' alt={contestant.name} />
                  <p>{contestant.name}</p>
                  <button><img src='/assets/heart.svg' /> <span>{contestant.votes}</span> </button>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        )}
      </div> */}
    </div>
  );
};

export default AdminDashboard;
