// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Registration from './components/registration/Registration';
import Login from './components/login/Login';
import Signup from './components/signup/Signup';
import Dashboard from './components/dashboards/Dashboard'; // Import Dashboard component
import ContestantProfile from './components/ContestantProfile';
import HomePage from './components/HomePage';
import Navigation from './components/navigation/Navigation';
import PostDetails from './components/contestants/PostDetails';
import Search from './components/navigation/Search'
import './App.css';
import AllContestants from './components/contestants/AllContestants';
import MensPhysiqueContestants from './components/contestants/MensPhysiqueContestants';
import BikiniModelContestants from './components/contestants/BikiniModelContestants';
import MensBodybuildingContestants from './components/contestants/MensBodybuildingContestants';
import TransformationContestants from './components/contestants/TransformationContestants';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const [visitors, setVisitors] = useState({
    centralValue: 0,
    fluctuationRange: 0,
  });
  const [randomNumber, setRandomNumber] = useState(null);


  useEffect(() => {
    const fetchVisitors = async () => {
      try {
        const response = await axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/visitors');
        setVisitors(response.data.visitors);
      } catch (error) {
        console.error('Error fetching visitors:', error);
      }
    };

    // Fetch visitors initially
    fetchVisitors();
    // Set up an interval to fetch visitors every 20 seconds
    const intervalId = setInterval(fetchVisitors, 10000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);


  useEffect(() => {
    const { centralValue, fluctuationRange } = visitors;
    const generateRandomNumber = () => {
      const randomValue = Math.round(
        centralValue + Math.random() * fluctuationRange - fluctuationRange / 2
      );
      setRandomNumber(randomValue);
    };

    // Generate a random number initially
    generateRandomNumber();

    // Set up an interval to generate a random number every 2 seconds
    const intervalId = setInterval(generateRandomNumber, 4000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [visitors]);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');

    if (storedToken) {
      axios
        .get('https://fitandfamousshowbackend.evertizsolutions.com/api/auth/verify', {
          headers: {
            Authorization: storedToken,
          },
        })
        .then((response) => {
          const { userType, userId } = response.data;
          setUserType(userType);
          setUserId(userId);
          setIsLoggedIn(true);
        })
        .catch((error) => {
          console.error('Token verification failed:', error.response.data.message);
          localStorage.removeItem('token');
          setError('Session expired. Please log in again.');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  const handleLogin = ({ token, userType, userId }) => {
    localStorage.setItem('token', token);
    setUserType(userType);
    setUserId(userId);
    setIsLoggedIn(true);
    setError(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUserType(null);
    setUserId(null);
    setIsLoggedIn(false);
    setError(null);
  };

  return (
    <Router>
      <div>
        <header>
          <div className='search-wrapper-mobile'>
            <Search />
          </div>
          <Link to="/"><img className='header_logo' src="/assets/FitAndFamous_logo.webp" /></Link>

          <Navigation isLoggedIn={isLoggedIn} userId={userId} onLogout={handleLogout} />
          <div className='live-wrapper'><img className='live' src='/assets/live.png' />
          <div className='live-visitors'><img src='/assets/live_visitors.png' /> <span>{randomNumber !== null ? randomNumber : 'Loading...'}</span></div></div>
        </header>

        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login onLogin={handleLogin} isLoggedIn={isLoggedIn} />} />
            <Route path="/register" element={<Registration userId={userId} />} />
            <Route path="/dashboard" element={<Dashboard userType={userType} userId={userId} />} />
            <Route path="/contestant-profile/:contestantId" element={<ContestantProfile userType={userType} userId={userId} />} />
            <Route path="/post-details/:postId" element={<PostDetails userId={userId} userType={userType} />} />
            <Route path="/category/all-contestants" element={<AllContestants />} />
            <Route path="/category/mens-physique" element={<MensPhysiqueContestants />} />
            <Route path="/category/mens-bodybuilding" element={<MensBodybuildingContestants />} />
            <Route path="/category/bikini-model" element={<BikiniModelContestants />} />
            <Route path="/category/transformation" element={<TransformationContestants />} />
          </Routes>
        </main>

        {loading && <p>Loading...</p>}
        {/* {error && <div style={{ color: 'white' }}>{error}</div>} */}
      </div>
    </Router>
  );
};

export default App;
