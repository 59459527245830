import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const ContestManagement = () => {
  const [contests, setContests] = useState([]);
  const [categories, setCategories] = useState(["Men's Physique", "Men's Bodybuilding", "Bikini Model", "Transformation"]);
  const [newContest, setNewContest] = useState({
    title: '',
    startDateTime: '',
    endDateTime: '',
    category: '',
  });

  const [editingContest, setEditingContest] = useState(null); // Track the contest being edited
  const editFormRef = useRef(null);
  const fetchContests = () => {
    axios.get('https://fitandfamousshowbackend.evertizsolutions.com/api/contests')
      .then(response => setContests(response.data))
      .catch(error => console.error('Error fetching contests:', error));
  };

  useEffect(() => {
    fetchContests();
  }, []);

  const handleCreateContest = (e) => {
    e.preventDefault();
    // Convert local time to UTC
    const startDateTimeUTC = new Date(newContest.startDateTime).toISOString();
    const endDateTimeUTC = new Date(newContest.endDateTime).toISOString();

    const contestDataUTC = {
      ...newContest,
      startDateTime: startDateTimeUTC,
      endDateTime: endDateTimeUTC
    };

    axios.post('https://fitandfamousshowbackend.evertizsolutions.com/api/contests', contestDataUTC)
      .then(() => {
        fetchContests();
        setNewContest({
          title: '',
          startDateTime: '',
          endDateTime: '',
          category: '',
        });
      })
      .catch(error => console.error('Error creating contest:', error));
  };

  const handleDeleteContest = (contestId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this contest?");
    if (confirmDelete) {
      axios.delete(`https://fitandfamousshowbackend.evertizsolutions.com/api/contests/${contestId}`)
        .then(() => {
          fetchContests();
        })
        .catch(error => console.error('Error deleting contest:', error));
    }
  };

  const handleEditContest = (contest) => {
    setEditingContest(contest);
    setNewContest({
      title: contest.title,
      startDateTime: new Date(contest.startDateTime).toISOString().slice(0, -8),
      endDateTime: new Date(contest.endDateTime).toISOString().slice(0, -8),
      category: contest.category,
    });
    // Scroll to the edit form
    editFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleUpdateContest = () => {
    // Convert local time to UTC
    const startDateTimeUTC = new Date(newContest.startDateTime).toISOString();
    const endDateTimeUTC = new Date(newContest.endDateTime).toISOString();

    const updatedContestData = {
      ...newContest,
      startDateTime: startDateTimeUTC,
      endDateTime: endDateTimeUTC
    };

    axios.put(`https://fitandfamousshowbackend.evertizsolutions.com/api/contests/${editingContest._id}`, updatedContestData)
      .then(() => {
        fetchContests();
        setEditingContest(null); // Reset editing state
        setNewContest({
          title: '',
          startDateTime: '',
          endDateTime: '',
          category: '',
        });
        window.location.reload();
      })
      .catch(error => console.error('Error updating contest:', error));
  };

  return (
    <div className='contest-management-wrapper'>

      {/* Contest List */}
      <div>
        <h3>Contest List</h3><br />
        {contests.length === 0 ? (
          <p>No contests found.</p>
        ) : (
          <ul>
            {contests.map(contest => (
              <li key={contest._id}>
                <p>{contest.title}</p>
                <p>category: {contest.category}</p> 
                <p>Start: {new Date(contest.startDateTime).toLocaleString()}</p>
                <p>End: {new Date(contest.endDateTime).toLocaleString()}</p>
                <button className='btn-inverse' onClick={() => handleDeleteContest(contest._id)}>Delete</button>
                <button className='btn-inverse' onClick={() => handleEditContest(contest)}>Edit</button>
              </li>
            ))}
          </ul>
        )}
      </div><br />

      {/* Create/Edit Contest Form */}
      <form ref={editFormRef} onSubmit={editingContest ? handleUpdateContest : handleCreateContest}>
        <h3>{editingContest ? 'Edit Contest' : 'Create New Contest'}</h3><br />
        <select
          value={newContest.category}
          onChange={(e) => setNewContest({ ...newContest, category: e.target.value })}
          required
        >
          <option value="" disabled>Select a category</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>{category}</option>
          ))}
        </select>
        <input
          type="datetime-local"
          value={newContest.startDateTime}
          onChange={(e) => setNewContest({ ...newContest, startDateTime: e.target.value })}
          placeholder='Start Date & Time'
          required
        />
        <input
          type="datetime-local"
          value={newContest.endDateTime}
          onChange={(e) => setNewContest({ ...newContest, endDateTime: e.target.value })}
          placeholder='End Date & Time'
          required
        />
        {editingContest ? (
          <button className='btn-inverse' type='submit'>Update Contest</button>
        ) : (
          <button className='btn-inverse' type='submit'>Create Contest</button>
        )}
      </form>
    </div>
  );
};

export default ContestManagement;
