import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const VoterDashboard = ({ userId }) => {
  const [userData, setUserData] = useState(null);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [votedContestants, setVotedContestants] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://fitandfamousshowbackend.evertizsolutions.com/api/user/${userId}`);
        setUserData(response.data.data);

         // Fetch voted contestants
         const votedContestantsIds = response.data.data.votedContestants;
         const votedContestantsData = await Promise.all(
           votedContestantsIds.map(async (contestantId) => {
             const contestantResponse = await axios.get(`https://fitandfamousshowbackend.evertizsolutions.com/api/user/${contestantId}`);
             return contestantResponse.data.data;
           })
         );

        // Fetch comments made by the voter
        const commentsResponse = await axios.get(`https://fitandfamousshowbackend.evertizsolutions.com/api/voter-comments/${userId}`);
        setComments(commentsResponse.data.comments);
        setVotedContestants(votedContestantsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  if (loading) {
    return <a>Loading user data...</a>;
  }

  return (
    <div className='dashboard-wrapper'>

     <div className='profile-details-wrapper voter-details'>
     <h3 className='voter-dashboard-heading'>Voter Dashboard</h3>
      <p>Welcome {userData.name}!</p>
      {/* <p>Age: {userData.age}</p>
      <p>Email: {userData.email}</p><br /><br /> */}
      {/* Display other user details as needed */}
     </div>
      
      {/* <div className='comments-section-wrapper'>
        <h3>Your Comments:</h3><br />
        {comments.length === 0 ? (
          <p>No comments found.</p>
        ) : (
          <ul>
            {comments.map((comment) => (
              <li className='post comment' key={comment._id}>
                {comment.text}
              </li>
            ))}
          </ul>
        )}
      </div>  */}

      <div className='voted-contestants-section-wrapper'>
        <h3>Voted Contestants:</h3><br />
        {votedContestants.length === 0 ? (
          <a>You haven't voted yet.</a>
        ) : (
          
          <ul className='votedContestants-wrapper'>
            {votedContestants.map((contestant) => (
              <li className='votedContestant-card' key={contestant._id}>
                <Link className='relative' to={`/contestant-profile/${contestant._id}`}>
                  <img src={contestant.profileImageUrl} className='contestant-profileImage' alt={contestant.name} />
                  <p>{contestant.name}</p>
                  <button><img src='/assets/heart.svg'/> <span>{contestant.votes}</span> </button>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>

    </div>
  );
};

export default VoterDashboard;
